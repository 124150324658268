import { ApolloClient } from '@apollo/client';
import { AlarmId } from '@blackbird/common/helpers';
import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import {
  AnnotationsLabelOptions,
  AxisPlotBandsOptions,
  Options as HighchartsOptions,
  SeriesArearangeOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesScatterOptions,
} from 'highcharts';
import Highcharts from 'highcharts';
import Annotations from 'highcharts/modules/annotations';
import Xrange from 'highcharts/modules/xrange';
import * as i18next from 'i18next';
import { has } from 'lodash';

import { DateTimeLabelFormatBasedOnBrowserLocale, xDateFormatBasedOnBrowserLocale } from '@/helpers/helper-functions';
import { determineLabel } from '@/helpers/highcharts';
import { truncateDataValue } from '@/helpers/truncation';
import { memoizedFiltering } from '@/lib/highcharts/filters';
import * as Types from '@/types';

import {
  HighchartsPoint,
  HighchartsVariancePoint,
  Point,
  dataFilters,
  getBatchAndStopsPlotBands,
  getOverridesPlotBands,
  pipePoints,
  pointProcessors,
} from './config-helpers';

// Modules can not be server-side rendered
if (typeof Highcharts === 'object') {
  Annotations(Highcharts);
  Xrange(Highcharts);
}

export const CHART_COLORS: Array<{
  color: string;
  variance: string;
}> = [
  { color: '#7cb5ec', variance: '#7cb5ec' },
  { color: '#8bbc21', variance: '#8bbc21' },
  { color: '#910000', variance: '#910000' },
  { color: '#1aadce', variance: '#1aadce' },
  { color: '#492970', variance: '#492970' },
  { color: '#f28f43', variance: '#f28f43' },
  { color: '#77a1e5', variance: '#77a1e5' },
  { color: '#c42525', variance: '#c42525' },
  { color: '#0d233a', variance: '#0d233a' },
  { color: '#a6c96a', variance: '#a6c96a' },
  { color: '#7a3a3a', variance: '#7a3a3a' },
  { color: '#ffa500', variance: '#ffa500' },
  { color: '#070', variance: '#070' },
  { color: '#48d1cc', variance: '#48d1cc' },
  { color: '#f08080', variance: '#f08080' },
  { color: '#008b8b', variance: '#008b8b' },
  { color: '#2f4f4f', variance: '#2f4f4f' },
  { color: '#8f3090', variance: '#8f3090' },
  { color: '#757a3a', variance: '#757a3a' },
  { color: '#00f', variance: '#00f' },
];

// For the MEASUREMENT_CHART_COLORS, we reuse the colors, except changing the first color to green.
export const MEASUREMENT_CHART_COLORS: Array<{
  color: string;
  variance: string;
}> = CHART_COLORS.map((color, i) => (i === 0 ? { color: 'green', variance: 'rgba(0, 127, 0, 0.3)' } : color));

export const MANUAL_PROCESS_COLORS = CHART_COLORS.map((color, i) =>
  i === 0 ? { color: '#aadea7', variance: 'rgba(0, 127, 0, 0.3)' } : color,
);

interface AlarmLimit {
  id: string;
  value: any;
  color: string;
  dashStyle:
    | 'Solid'
    | 'ShortDash'
    | 'ShortDot'
    | 'ShortDashDot'
    | 'ShortDashDotDot'
    | 'Dot'
    | 'Dash'
    | 'LongDash'
    | 'DashDot'
    | 'LongDashDot'
    | 'LongDashDotDot';
  width: number;
  label: {
    text: string;
    align: 'left' | 'right' | 'center';
    x: number;
    y: number;
  };
  zIndex?: number;
  from: number;
  to: number;
}

type BATCH = Pick<Schema.Batch, 'actualStart' | 'actualStop' | 'amount' | 'batchId' | 'batchNumber' | 'stats'> & {
  product: Pick<Schema.Product, 'validatedLineSpeed' | 'expectedAverageSpeed' | 'name' | 'packaging'>;
};

export interface Sensor {
  peripheralId?: string;
  isScrapCounter: boolean;
  name: string;
  description?: string;
  time: Array<{
    samples: Schema.Sample[];
    batches?: { items: BATCH[] };
    stops?: Schema.Stop[];
    dataOverrides?: Schema.DataOverride[];
  }>;

  config?: Pick<Schema.SensorConfig, 'type' | 'chartTimeScale' | 'chart' | 'expectedSpeed' | 'validatedSpeed'> &
    Partial<Schema.SensorConfig>;
  alarms?: Schema.Alarm[];
  horizontalAnnotations?: Schema.HorizontalAnnotation[];
  verticalAnnotations?: Schema.VerticalAnnotation[];
  colorIndex?: number;
}

export interface ToggleLegends {
  validatedSpeed: boolean;
  expectedSpeed: boolean;
  showStops: boolean;
  showAnnotations: boolean;
}

export type LegendsFilter = {
  [x in keyof ToggleLegends]?: boolean;
};

export interface GraphConfig {
  theme: Theme;
  t: i18next.TFunction;
  language: string;
  height: number | string;
  refetch?: (start: Date, end: Date, isZoomEvent?: boolean) => void;
  sensors?: Sensor[];
  time?: Types.TimeDate;
  client?: ApolloClient<object>;
  horizontalAnnotations: AlarmLimit[];
  verticalAnnotations: AlarmLimit[];
  colorsWithHidden?: Array<{
    color: string;
    variance: string;
  }>;
  onClickStop?: (...args: unknown[]) => unknown;
  disableTooltip?: boolean;
  disableAxisLabel?: boolean;
  disableBatchPlotBands?: boolean;
  disableExportMenu?: boolean;
  showTargets?: boolean;
  setLegends?: (tl: ToggleLegends) => void;
  selectedTime?: Date;
  setSelectedTime?: (time: Date) => void;
  onClickHandler?: <T>(input: T) => void;
  onAnnotationClicked?: (annotation: Schema.VerticalAnnotation) => void;

  legends?: ToggleLegends;
  // Configures if legends should be shown.
  // Useful for not showing legends on the peripherals overview page
  showLegend?: boolean;
  hideLegends?: LegendsFilter;
}

// This method was made to better control the styling of the tooltips.
// Problems occured when trying to color previous <b> tag elements,
// which is why we now use font-weight: bold instead.
const colorSpanWrap = (
  text: string,
  color: string,
  options?: {
    bold?: boolean;
    newLine?: boolean;
  },
) => {
  if (color) {
    let style = '';
    if (options && options.bold) {
      style = 'color: ' + color + '; font-weight: bold;';
    } else {
      style = 'color: ' + color + ';';
    }
    if (options && options.newLine) {
      return `<span style="${style}">${text}</span><br />`;
    }
    return `<span style="${style}">${text} </span>`;
  }

  if (options && options.newLine) {
    return `<span>${text}</span><br />`;
  }

  return `<span>${text} </span>`;
};

/**
 * Construct a list of alarm limits to display on the graph.
 */
const createNonOverlappinAlarmLimits = (
  samplesTimeEnd: Date | null,
  theme: Theme,
  alarms: Schema.Alarm[],
): AlarmLimit[] => {
  const alarmLimits: AlarmLimit[] = [];

  // We avoid overlaps by moving the labels around based on their grouping number:
  //    0: left above
  //    1: right above
  //    2: left below
  //    3: right below
  //    4: center below
  //    5: center above
  // etc...
  const groupingCount: number[] = [];

  // Only show enabled alarms on the live graph. Also only display alarms that have a configured x
  alarms = alarms.filter((alarm) => alarm.enabled && alarm.alarmConfiguration.x);
  // Construct the alarm limits.
  alarms.map((alarm) => {
    // We made sure that `alarm.alarmConfiguration.x` has a value in the above filter
    const x = alarm.alarmConfiguration.x!;
    if (has(groupingCount, x.toString())) {
      groupingCount[x] += 1;
    } else {
      groupingCount[x] = 0;
    }
    const grouping = groupingCount[x];

    let color = '#d9534f';
    if (theme && theme.blackbird && theme.blackbird.alarms && theme.blackbird.alarms.type[alarm.type]) {
      color = theme.blackbird.alarms.type[alarm.type].color;
    }

    // FIXME: Dunno what the implications of an alarm without a time range would
    // be - let's wing it
    if (!alarm.timeRange) {
      return;
    }

    const alarmTo = alarm.timeRange.to ? new Date(alarm.timeRange.to).getTime() : Date.now();
    alarmLimits.push({
      id: alarm.id,
      value: x,
      // FIXME: I don't think it makes sense to have falsy time ranges - this should be fixed in the API
      from: new Date(alarm.timeRange.from!).getTime(),
      // Clip the time into the requested time. Do not let the alarm time be after the requested time.
      to: Math.min(samplesTimeEnd?.getTime() ?? Number.MAX_SAFE_INTEGER, alarmTo),
      color,
      dashStyle: 'LongDashDotDot',
      width: 1,
      // useHTML: true,
      label: {
        // text: '<span style="color: ' + color + ';">♦ </span><span>' + alarm.name + '</span>',
        text: alarm.name,
        align: grouping >= 4 && grouping <= 5 ? 'center' : grouping % 2 === 0 ? 'left' : 'right',
        y: grouping % 4 <= 1 ? -5 : 16,
        x: grouping >= 4 && grouping <= 5 ? 0 : grouping % 2 === 0 ? 5 : -5,
      },
      zIndex: 4,
    });
  });
  return alarmLimits;
};

const getBatchSpeed = (x: number, batches: BATCH[], speedType: string) => {
  const matchingBatch = batches.map((batch) => {
    if (
      !(x < Date.parse(batch.actualStart as unknown as string) || Date.parse(batch.actualStop as unknown as string) < x)
    ) {
      if (speedType === 'expected') {
        return batch.product.expectedAverageSpeed;
      } else if (speedType === 'validated') {
        return batch.product.validatedLineSpeed;
      }
    }
    return null;
  });
  return matchingBatch ? matchingBatch.find((batch) => batch) : null;
};

const getMaxValidatedSpeed = (
  batches: BATCH[],
  validatedSpeed: number,
  chartTimeScale = Schema.ChartTimeScale.MINUTE,
) => {
  let max = 0;
  batches.map((batch) => {
    const batchSpeed =
      batch.product.validatedLineSpeed *
      (chartTimeScale === Schema.ChartTimeScale.HOUR ? 60 : chartTimeScale === Schema.ChartTimeScale.DAY ? 60 * 24 : 1);
    max = batchSpeed > max ? batchSpeed : max;
  });
  return max === 0 ? validatedSpeed : max;
};

const customUnitTranslation = (
  t: i18next.TFunction,
  transBatchDataUnit: string,
  chartTimeScale = Schema.ChartTimeScale.MINUTE,
) => {
  if (chartTimeScale === Schema.ChartTimeScale.HOUR) {
    return t(['shared:customUnitPrHour'], {
      defaultValue: '{{transBatchDataUnit}}/hour',
      transBatchDataUnit,
    });
  }
  if (chartTimeScale === Schema.ChartTimeScale.DAY) {
    return t(['shared:customUnitPrDay'], {
      defaultValue: '{{transBatchDataUnit}}/day',
      transBatchDataUnit,
    });
  }
  return t(['shared:customUnitPrMin'], {
    defaultValue: '{{transBatchDataUnit}}/min',
    transBatchDataUnit,
  });
};

const getBatchInfo = (
  batches: BATCH[],
  dataUnit: string,
  noActStops: Schema.Stop[],
  t: i18next.TFunction,
  x: number,
  chartTimeScale = Schema.ChartTimeScale.MINUTE,
) => {
  // FIXME: Handle the case where actualStart isn't set - or change the
  // interface if that is never possible.
  const batchStart = batches.map((batch) => new Date(batch.actualStart!).getTime());
  const batchStop = batches.map((batch) => (batch.actualStop ? new Date(batch.actualStop).getTime() : Date.now()));

  let currentBatch: BATCH | undefined;
  let i = 0;
  for (i = 0; i < batchStart.length; i++) {
    if (x > batchStart[i] && x < batchStop[i]) {
      currentBatch = batches[i];
      break;
    }
  }
  let noBatchInformation = true;
  let batchString = t(['shared:noBatchInformation'], { defaultValue: 'No batch information' });

  if (currentBatch) {
    let noActTimeInBatch = 0;

    noActStops.forEach((stop) => {
      // FIXME: Handle nullish values for from/to
      const from = new Date(stop.timeRange.from!).getTime();
      const to = new Date(stop.timeRange.to!).getTime();
      if (from >= batchStart[i] && from <= batchStop[i]) {
        if (to > batchStop[i]) {
          noActTimeInBatch += batchStop[i] - from;
        } else {
          noActTimeInBatch += to - from;
        }
      }
    });

    const produced = currentBatch?.stats?.data?.produced ?? 0;

    const batchExpectedSpeed =
      currentBatch.product.expectedAverageSpeed *
      (chartTimeScale === Schema.ChartTimeScale.HOUR ? 60 : chartTimeScale === Schema.ChartTimeScale.DAY ? 60 * 24 : 1);
    const batchValidatedSpeed =
      currentBatch.product.validatedLineSpeed *
      (chartTimeScale === Schema.ChartTimeScale.HOUR ? 60 : chartTimeScale === Schema.ChartTimeScale.DAY ? 60 * 24 : 1);
    const averageProduced =
      produced /
      ((batchStop[i] - (batchStart[i] + noActTimeInBatch)) /
        ((chartTimeScale === Schema.ChartTimeScale.HOUR
          ? 60 * 60
          : chartTimeScale === Schema.ChartTimeScale.DAY
          ? 60 * 60 * 24
          : 60) *
          1000));
    const targetToExpected = (100 * produced) / currentBatch.amount;
    const producedStatus = averageProduced > batchExpectedSpeed ? 'color:green' : 'color:red';
    const targetStatus = targetToExpected > 100 ? 'color:green' : 'color:red';

    const batchDataUnit = currentBatch.product?.packaging?.unit ?? dataUnit;
    const batchDataUnitLabel = customUnitTranslation(t, batchDataUnit, chartTimeScale);
    batchString = `${t(['shared:product'], { defaultValue: 'Product' })}: ${currentBatch.product.name} <br />
                  ${t(['shared:batchNumber'], { defaultValue: 'Batch/PO number' })}: ${currentBatch.batchNumber} <br />
                  ${t(['shared:batchSize'], { defaultValue: 'Batch size' })}: ${currentBatch.amount} <br />
                  ${t(['shared:itemsProduced'], { defaultValue: 'Items produced' })}: ${Math.floor(produced)} <br />
                  ${t(['shared:expectedProductSpeed'], {
                    defaultValue: 'Expected product speed',
                  })}: ${batchExpectedSpeed} ${batchDataUnitLabel}<br />
                  ${t(['shared:validatedProductSpeed'], {
                    defaultValue: 'Validated product speed',
                  })}: ${batchValidatedSpeed} ${batchDataUnitLabel}<br />
                  ${t(['shared:averageProduced'], {
                    defaultValue: 'Average produced',
                  })}: <span style="${producedStatus}">${averageProduced.toFixed(
      1,
    )} </span> ${batchDataUnitLabel} <br />
                  ${t(['shared:completePercentage'], {
                    defaultValue: 'Complete percentage',
                  })}: <span style="${targetStatus}">${targetToExpected.toFixed(1)} </span> %`;
    noBatchInformation = false;
  }
  return { batchString, noBatchInformation };
};

const getPlotBands = (
  isASingleSensor: Sensor | null,
  time: Types.TimeDate | undefined,
  theme: Theme,
  showStops: boolean,
  onClickHandler?: <T>(input: T) => void,
): AxisPlotBandsOptions[] | undefined => {
  if (!isASingleSensor) {
    return undefined;
  }

  const plotbands = [];
  if ((isASingleSensor?.time?.[0]?.batches?.items ?? []).length || isASingleSensor?.time?.[0]?.stops) {
    const stops = isASingleSensor?.time?.[0]?.stops ?? [];
    // Ensures that we do not render a massive amount of stops causing the page to freeze
    const renderedStops = stops.length > 500 ? [] : stops;
    plotbands.push(
      ...getBatchAndStopsPlotBands(
        isASingleSensor?.time?.[0]?.batches?.items ?? [],
        renderedStops,
        time,
        theme,
        showStops,
      ),
    );
  }
  if ((isASingleSensor?.time?.[0]?.dataOverrides ?? []).length) {
    plotbands.push(
      ...getOverridesPlotBands(isASingleSensor?.time?.[0]?.dataOverrides ?? [], time, theme, onClickHandler),
    );
  }

  return plotbands;
};

const getConfig = ({
  t,
  language,
  theme,
  height,
  refetch,
  sensors = [],
  time,
  client,
  onClickStop,
  colorsWithHidden,
  disableTooltip = false,
  disableBatchPlotBands = false,
  disableAxisLabel = false,
  disableExportMenu = false,
  showTargets = false,
  legends = {
    validatedSpeed: false,
    expectedSpeed: false,
    showStops: false,
    showAnnotations: false,
  },
  setLegends = Function.prototype as any,
  setSelectedTime: setSelectedTime,
  selectedTime: selectedTime,
  onClickHandler,
  onAnnotationClicked,
  showLegend = true,
  hideLegends = {},
}: GraphConfig) => {
  const alarms = sensors.reduce((alarms, { alarms: sensorAlarms }) => {
    if (sensorAlarms) {
      alarms.push(...sensorAlarms);
    }

    return alarms;
  }, [] as Schema.Alarm[]);

  const samples = sensors?.[0]?.time?.[0]?.samples;
  const lastIndex = samples?.length - 1 ?? 0;
  const samplesEndTime = samples?.[lastIndex]?.timeRange.to;
  const alarmLimits: AlarmLimit[] = alarms
    ? createNonOverlappinAlarmLimits(samplesEndTime ? new Date(samplesEndTime) : null, theme, alarms)
    : [];

  const hasOnlyOneSensor = sensors.length === 1;

  const [primarySensor = null] = sensors;

  const primarySensorChartTimeScale = primarySensor?.config?.chartTimeScale ?? Schema.ChartTimeScale.MINUTE;

  const [, yAxisLabel] = determineLabel(t, primarySensor);

  // TODO: Clean up this mess - this config contains so much redundant and
  // branching code. 70% is unnecessary.
  const processorConfiguration = null || primarySensor?.config?.chart?.dataFilter || Schema.DataFilter.AVERAGE_SPEED;

  const isUptime = processorConfiguration === Schema.DataFilter.UPTIME;
  const tooltipEnabled = !disableTooltip;
  const exportEnabled = tooltipEnabled && !disableExportMenu;

  const expectedSpeed =
    Math.round(
      (sensors[0]?.config?.expectedSpeed ?? 0) *
        (primarySensorChartTimeScale === Schema.ChartTimeScale.HOUR
          ? 60
          : primarySensorChartTimeScale === Schema.ChartTimeScale.DAY
          ? 60 * 24
          : 1) *
        100,
    ) / 100;

  const validatedSpeed =
    Math.round(
      Math.max(...sensors.map((s) => s.config?.validatedSpeed || 0)) *
        (primarySensorChartTimeScale === Schema.ChartTimeScale.HOUR
          ? 60
          : primarySensorChartTimeScale === Schema.ChartTimeScale.DAY
          ? 60 * 24
          : 1) *
        100,
    ) / 100;
  const maxValidatedSpeed = getMaxValidatedSpeed(
    sensors[0]?.time?.[0]?.batches?.items ?? [],
    validatedSpeed,
    primarySensorChartTimeScale,
  );

  let anyDateWithData: Date | undefined;

  let cleanupFunctions: Function[] = [];

  const plotConfig: HighchartsOptions = {
    boost: {
      enabled: false,
      // Perform value to pixel translations in the shader, rather than on the GPU. This may have adverse
      // effects on some datasets (especially those where floating point precision may be an issue, such
      // as timestamps with small intervals).
      useGPUTranslations: false,
      // If set to true, a native Float32Array buffer will be used directly for adding series points to the
      // internal vertex buffers. This speeds up series processing significantly. It’s well-suited for bubble,
      // scatter, column, and bar charts.
      usePreallocated: false,
      // If set to true, the whole chart will be boosted if all of its series are supported by the boost module,
      // and if at least one series crosses its boost threshold.
      allowForce: false,
    },

    exporting: {
      enabled: exportEnabled,
      chartOptions: {
        title: {
          text: sensors.map((s) => s.name).join(','),
        },
      },
      scale: 2,
      sourceWidth: 1200,
      // @ts-ignore
      menuItemDefinitions: {
        printChart: {
          text: t(['shared:printChart'], { defaultValue: 'Print chart' }),
        },
        downloadPNG: {
          text: t(['shared:downloadPNG'], { defaultValue: 'Download PNG image' }),
        },
        downloadJPEG: {
          text: t(['shared:downloadJPEG'], { defaultValue: 'Download JPEG image' }),
        },
        downloadPDF: {
          text: t(['shared:downloadPDF'], { defaultValue: 'Download PDF image' }),
        },
        downloadSVG: {
          text: t(['shared:downloadSVG'], { defaultValue: 'Download SVG image' }),
        },
      },
      buttons: {
        contextButton: {
          y: 0,
          x: 0,
          verticalAlign: 'bottom',
          menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downdloadSVG'],
        },
      },
    },
    chart: {
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
      // @ts-ignore - apparently not documented? But it does enable zooming if set...
      zoomType: 'x',
      height,
      events: {
        click(e: Event & Types.HighchartsChart) {
          if (setSelectedTime && e?.xAxis?.[0]?.value) {
            setSelectedTime(new Date(e?.xAxis?.[0]?.value));
          }
        },
        selection(e: Event & Types.HighchartsChart) {
          if (!e.resetSelection && refetch && sensors) {
            e.preventDefault();
            refetch(new Date(e.xAxis[0].min), new Date(e.xAxis[0].max), true);
          }
        },
        load(_: any) {
          // NOTE: Highcharts has no onClick callback for annotations, so manually attach
          // a listener on annotation labels, so that we can open a dialog for modifying annotations
          if (onAnnotationClicked !== undefined) {
            let chart = this as any;
            let annotations = chart.annotations;
            annotations.forEach((annotation: any) => {
              annotation.labels.forEach((labelEl: any, idx: number) => {
                const removeEventFn = Highcharts.addEvent(labelEl.graphic.element, 'click', (e) => {
                  let id = annotation.options.labels[idx].id;
                  let label = annotation.options.labels[idx].text;
                  let obj: Schema.VerticalAnnotation = {
                    id,
                    label,
                    tags: [],
                    timestamp: annotation.options.labels[idx].point.x,
                    timestampEnd: null,
                  };
                  onAnnotationClicked(obj);
                });
                cleanupFunctions.push(removeEventFn);
              });
            });
          }
        },
        destroy: () => {
          cleanupFunctions.forEach((fn) => {
            fn();
          });
        },
      } as any,
    },
    series: sensors.reduce<Array<SeriesArearangeOptions | SeriesColumnOptions | SeriesLineOptions>>(
      (series, sensor, index) => {
        // NOTE: The cast here is to workaround __typename - omit recursively fails as well :(
        const querySamples = ((sensor?.time?.[0]?.samples ?? []) as Point[]).filter((sample): sample is Point => {
          return !!sample.timeRange.from && !!sample.timeRange.to;
        });

        if (!querySamples.length) {
          return series;
        }

        if (querySamples.length && !anyDateWithData) {
          anyDateWithData = new Date(querySamples[0].timeRange.from!);
        }

        const batches = sensor?.time?.[0]?.batches?.items ?? [];
        const type = sensor?.config?.type ?? Schema.SensorType.COUNTER;
        const isMeasurement = type === Schema.SensorType.MEASUREMENT;

        const chartTimeScale = sensor?.config?.chartTimeScale ?? Schema.ChartTimeScale.MINUTE;

        const [dataUnit, yAxisLabel] = determineLabel(t, sensor || null);

        const sensorIndex = sensor && sensor.colorIndex ? sensor.colorIndex : index;
        const color = colorsWithHidden
          ? colorsWithHidden[sensorIndex % CHART_COLORS.length]
          : isMeasurement
          ? MEASUREMENT_CHART_COLORS[sensorIndex % MEASUREMENT_CHART_COLORS.length]
          : CHART_COLORS[sensorIndex % CHART_COLORS.length];
        const textColor = primarySensor ? 'inherit' : color.color;

        const noActStops =
          batches && batches.length
            ? (sensor?.time?.[0]?.stops ?? []).filter((s) => s && s.stopCause && s.stopCause.stopType === 'NO_ACT')
            : [];

        const showTargetSpeeds =
          showTargets && type && (type.startsWith('COUNTER') || type === Schema.SensorType.MANUAL_PROCESS);

        const scale = (() => {
          if (isMeasurement) {
            return 0;
          }

          switch (chartTimeScale) {
            case Schema.ChartTimeScale.DAY:
              return (1).days;
            case Schema.ChartTimeScale.HOUR:
              return (1).hours;
            case Schema.ChartTimeScale.MINUTE:
              return (1).minutes;
            default:
              return 0;
          }
        })();

        const isScrapCounter = sensor.isScrapCounter;

        // FIXME: Pick chart selection as first priority
        const dataFilter = null || sensor.config?.chart?.dataFilter || Schema.DataFilter.AVERAGE_SPEED;
        const processor: Schema.DataFilter = scale && !isScrapCounter ? dataFilter : Schema.DataFilter.NONE;

        const sampleProcessor = pointProcessors[processor];
        const voidProcessor = pointProcessors.VOID;
        const varianceProcessor = pointProcessors.VARIANCE;

        // NOTE: Points are always evenly distributed from the API
        const [firstSample] = querySamples;

        const perPointSpan =
          new Date(firstSample.timeRange.to).getTime() - new Date(firstSample.timeRange.from).getTime();

        const now = Date.now();
        const composers = [
          voidProcessor({ average: isMeasurement, now }),
          sampleProcessor({ scale, average: isMeasurement, perPointSpan }),
          varianceProcessor({ isMeasurement }),
        ];

        // FIXME: Once TypeScript gets smart enough with detecting tuple length
        // move the cast to the type argument of reduce.
        const [voidData, samples, variances] = pipePoints(querySamples, composers) as [
          HighchartsPoint[],
          HighchartsPoint[],
          HighchartsVariancePoint[],
        ];

        const filteredSamples = [Schema.DataFilter.NONE, Schema.DataFilter.UPTIME].every(
          (filter) => processor !== filter,
        )
          ? memoizedFiltering(samples, dataFilters, {
              peripheralId: `${sensor?.peripheralId}`,
            })
          : samples;

        const impulses = processor === Schema.DataFilter.NONE && !isMeasurement;

        series.push(
          // serie 0, main data line / area graph
          {
            // @ts-ignore
            boostThreshold: 1,
            animation: false,
            type: impulses ? 'column' : 'line',
            id: sensor.peripheralId,
            name: '',
            data: filteredSamples,
            color: color.color,
            showInLegend: false,
            tooltip: {
              pointFormatter(this: any) {
                const { batchString, noBatchInformation } = getBatchInfo(
                  batches,
                  dataUnit,
                  noActStops,
                  t,
                  this.x,
                  chartTimeScale,
                );

                if (type === Schema.SensorType.MEASUREMENT) {
                  return (
                    colorSpanWrap(`${t(['shared:avgAbbr'], { defaultValue: 'Avg' })}: `, textColor) +
                    colorSpanWrap(truncateDataValue(this.y), textColor, {
                      bold: true,
                    }) +
                    colorSpanWrap(` ${dataUnit}`, textColor, { bold: false, newLine: true }) +
                    (disableBatchPlotBands ? '' : colorSpanWrap(batchString, textColor, { newLine: true }))
                  );
                }

                const isIntegerValue = primarySensor?.isScrapCounter;
                if (noBatchInformation && this.series.chart.series[3]?.userOptions.visible) {
                  return (
                    colorSpanWrap(this.y.toFixed(isIntegerValue ? 0 : 2), textColor, { bold: true }) +
                    colorSpanWrap(dataUnit, textColor, { newLine: true }) +
                    (disableBatchPlotBands ? '' : colorSpanWrap(batchString, textColor, { newLine: true })) +
                    colorSpanWrap(
                      `${t(['shared:expectedSpeed'], {
                        defaultValue: 'Expected speed',
                      })}: ${expectedSpeed} ${dataUnit}`,
                      textColor,
                      { newLine: true },
                    ) +
                    colorSpanWrap(
                      `${t(['shared:validatedSpeed'], {
                        defaultValue: 'Validated speed',
                      })}: ${validatedSpeed} ${dataUnit}`,
                      textColor,
                      { newLine: true },
                    )
                  );
                }
                return (
                  colorSpanWrap(this.y.toFixed(isIntegerValue ? 0 : 2), textColor, { bold: true }) +
                  colorSpanWrap(dataUnit, textColor, { newLine: true }) +
                  (disableBatchPlotBands ? '' : colorSpanWrap(batchString, textColor, { newLine: true }))
                );
              },
            },

            zIndex: 9999,
            events: {
              click(e: Event & Types.HighchartsChart) {
                if (setSelectedTime) {
                  setSelectedTime(new Date(e?.point?.x));
                }
              },
            },
          },
          // serie 1, variance graph used with analog
          {
            animation: false,
            type: 'arearange',
            name: 'Variance',
            id: `${sensor.peripheralId}-var`,
            data: variances,
            color: color.variance,
            lineWidth: 0,
            fillOpacity: 0.3,
            linkedTo: ':previous',
            tooltip: {
              pointFormatter(this: any) {
                return (
                  colorSpanWrap(`${t(['shared:minAbbr'], { defaultValue: 'Min' })}: `, textColor) +
                  colorSpanWrap(truncateDataValue(this.low), textColor, { bold: true }) +
                  colorSpanWrap(` ${dataUnit}`, textColor, { newLine: true }) +
                  colorSpanWrap(`${t(['shared:maxAbbr'], { defaultValue: 'Max' })}: `, textColor) +
                  colorSpanWrap(truncateDataValue(this.high), textColor, { bold: true }) +
                  colorSpanWrap(` ${dataUnit}`, textColor, { newLine: true })
                );
              },
            },
            zIndex: 0,
            marker: {
              enabled: false,
            },
            visible: type === Schema.SensorType.MEASUREMENT,
            events: {
              click(e: Event & Types.HighchartsChart) {
                if (setSelectedTime && e?.point?.x) {
                  setSelectedTime(new Date(e?.point?.x));
                }
              },
            },
          },
          // serie 2, missing data graph
          {
            animation: false,
            type: 'line',
            name: 'Missing data',
            id: `${sensor.peripheralId}-md`,
            data: voidData,
            dashStyle: 'Dot',
            tooltip: {
              pointFormatter(this: any) {
                const { batchString } = getBatchInfo(batches, dataUnit, noActStops, t, this.x, chartTimeScale);
                return (
                  colorSpanWrap(
                    `${t(['shared:missingData'], { defaultValue: 'Missing Data' })}!`,
                    primarySensor ? 'red' : color.color,
                    { bold: true, newLine: true },
                  ) + (disableBatchPlotBands ? '' : colorSpanWrap(batchString, textColor, { newLine: true }))
                );
              },
            },
            color: 'red',
            lineWidth: 2,
            linkedTo: '0',
            events: {
              click(e: Event & Types.HighchartsChart) {
                if (setSelectedTime && e?.point?.x) {
                  setSelectedTime(new Date(e?.point?.x));
                }
              },
            },
          },
        );
        if (hideLegends.expectedSpeed !== true) {
          series.push(
            // serie 3, expected speed line
            {
              visible: isUptime ? false : legends.expectedSpeed,
              animation: false,
              type: 'line',
              name: t(['shared:expectedSpeed'], { defaultValue: 'Expected speed' }),
              id: `${sensor.peripheralId}-expSpeed`,
              data: showTargetSpeeds
                ? querySamples.map((p) => {
                    const x = new Date(p.timeRange.from).getTime();
                    const speed =
                      (getBatchSpeed(x, batches, 'expected') || 0) *
                      (chartTimeScale === Schema.ChartTimeScale.HOUR
                        ? 60
                        : chartTimeScale === Schema.ChartTimeScale.DAY
                        ? 60 * 24
                        : 1);
                    return {
                      x,
                      y: speed ? speed : expectedSpeed,
                    };
                  })
                : null,
              color: 'black',
              dashStyle: 'Dash',
              cursor: false,
              showInLegend: showTargetSpeeds,
              events: {
                legendItemClick(this: any, e: Event) {
                  e.preventDefault();
                  setTimeout(() => {
                    setLegends({
                      ...legends,
                      expectedSpeed: !legends.expectedSpeed,
                    });
                  });
                },

                click(e: Event & Types.HighchartsChart) {
                  if (setSelectedTime && e?.point?.x) {
                    setSelectedTime(new Date(e?.point?.x));
                  }
                },
              },
              marker: {
                enabled: false,
                states: {
                  hover: {
                    animation: false,
                    enabled: false,
                    radiusPlus: 0,
                    lineWidthPlus: 0,
                  },
                },
              },
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              enableMouseTracking: false,
            } as unknown as SeriesLineOptions, // NOTE this was already broken, but I don't know if the types are inaccurate, or it's actually broken.
          );
        }
        if (hideLegends.validatedSpeed !== true) {
          series.push(
            // serie 4, validated speed line
            {
              visible: isUptime ? false : legends.validatedSpeed,
              animation: false,
              type: 'line',
              name: t(['shared:validatedSpeed'], { defaultValue: 'Validated speed' }),
              id: `${sensor.peripheralId}-valSpeed`,
              data: showTargetSpeeds
                ? querySamples.map((p) => {
                    const x = new Date(p.timeRange.from).getTime();
                    const speed =
                      (getBatchSpeed(x, batches, 'validated') || 0) *
                      (chartTimeScale === Schema.ChartTimeScale.HOUR
                        ? 60
                        : chartTimeScale === Schema.ChartTimeScale.DAY
                        ? 60 * 24
                        : 1);
                    return {
                      x,
                      y: speed ? speed : validatedSpeed,
                    };
                  })
                : null,
              color: 'green',
              dashStyle: 'Dash',
              cursor: false,
              showInLegend: showTargetSpeeds,
              events: {
                legendItemClick(this: any, e: Event) {
                  e.preventDefault();
                  setTimeout(() => {
                    setLegends({
                      ...legends,
                      validatedSpeed: !legends.validatedSpeed,
                    });
                  });
                },

                click(e: Event & Types.HighchartsChart) {
                  if (setSelectedTime && e?.point?.x) {
                    setSelectedTime(new Date(e?.point?.x));
                  }
                },
              },
              // linkedTo: ':previous',
              marker: {
                enabled: false,
                states: {
                  hover: {
                    animation: false,
                    enabled: false,
                    radiusPlus: 0,
                    lineWidthPlus: 0,
                  },
                },
              },
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              enableMouseTracking: false,
            } as unknown as SeriesLineOptions, // NOTE this was already broken, but I don't know if the types are inaccurate, or it's actually broken.,
          );
        }
        if (hideLegends.showStops !== true) {
          series.push(
            // serie 6, line including stops
            {
              visible: legends.showStops,
              animation: false,
              type: 'boxplot',
              color: 'red',
              name: t(['shared:showStops'], { defaultValue: 'Show stops' }),
              id: `${sensor.peripheralId}-showStops`,
              cursor: false,
              showInLegend: showTargetSpeeds,
              events: {
                legendItemClick(this: any, e: Event) {
                  e.preventDefault();
                  setTimeout(() => {
                    setLegends({
                      ...legends,
                      showStops: !legends.showStops,
                    });
                  });
                },
              },
              // linkedTo: ':previous',
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              enableMouseTracking: false,
              zIndex: 0,
            } as unknown as SeriesLineOptions, // NOTE this was already broken, but I don't know if the types are inaccurate, or it's actually broken.,
          );
        }
        if (hideLegends.showAnnotations !== true) {
          series.push(
            // serie 7, line including annotations
            {
              visible: legends.showAnnotations,
              animation: false,
              type: 'polygon',
              color: 'black',
              name: t(['shared:showAnnotations'], { defaultValue: 'Show annotations' }),
              id: `${sensor.peripheralId}-showAnnotations`,
              cursor: false,
              showInLegend: hasOnlyOneSensor,
              events: {
                legendItemClick(this: any, e: Event) {
                  e.preventDefault();
                  setTimeout(() => {
                    setLegends({
                      ...legends,
                      showAnnotations: !legends.showAnnotations,
                    });
                  });
                },
              },
              // linkedTo: ':previous',
              states: {
                hover: {
                  lineWidthPlus: 0,
                },
              },
              enableMouseTracking: false,
            } as unknown as SeriesLineOptions, // NOTE this was already broken, but I don't know if the types are inaccurate, or it's actually broken.,
          );
        }
        return series;
      },
      Array<SeriesLineOptions>(),
    ),
    credits: {
      enabled: false,
    },
    legend: {
      enabled: showLegend,
    },
    xAxis: [
      {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: DateTimeLabelFormatBasedOnBrowserLocale(language).millisecond,
          second: DateTimeLabelFormatBasedOnBrowserLocale(language).second,
          minute: DateTimeLabelFormatBasedOnBrowserLocale(language).minute,
          hour: DateTimeLabelFormatBasedOnBrowserLocale(language).hour,
          day: DateTimeLabelFormatBasedOnBrowserLocale(language).day,
          week: DateTimeLabelFormatBasedOnBrowserLocale(language).week,
          month: DateTimeLabelFormatBasedOnBrowserLocale(language).month,
          year: DateTimeLabelFormatBasedOnBrowserLocale(language).year,
        },
        title: {
          text: disableAxisLabel ? '' : t(['shared:date'], { defaultValue: 'Date' }),
        },
        min: time?.from?.getTime(),
        max: time?.to?.getTime(),
        // We only show the plot bands when we are graphing one series.
        plotBands: getPlotBands(primarySensor, time, theme, legends.showStops, onClickHandler),
      },
      {
        type: 'datetime',
        visible: false,
        linkedTo: 0,
        dateTimeLabelFormats: {
          millisecond: DateTimeLabelFormatBasedOnBrowserLocale(language).millisecond,
          second: DateTimeLabelFormatBasedOnBrowserLocale(language).second,
          minute: DateTimeLabelFormatBasedOnBrowserLocale(language).minute,
          hour: DateTimeLabelFormatBasedOnBrowserLocale(language).hour,
          day: DateTimeLabelFormatBasedOnBrowserLocale(language).day,
          week: DateTimeLabelFormatBasedOnBrowserLocale(language).week,
          month: DateTimeLabelFormatBasedOnBrowserLocale(language).month,
          year: DateTimeLabelFormatBasedOnBrowserLocale(language).year,
        },
        title: {
          text: 'Date1',
        },
      },
      {
        type: 'datetime',
        linkedTo: 0,
        // minRange: 10 * 60 * 1000,
        visible: false,
        dateTimeLabelFormats: {
          millisecond: DateTimeLabelFormatBasedOnBrowserLocale(language).millisecond,
          second: DateTimeLabelFormatBasedOnBrowserLocale(language).second,
          minute: DateTimeLabelFormatBasedOnBrowserLocale(language).minute,
          hour: DateTimeLabelFormatBasedOnBrowserLocale(language).hour,
          day: DateTimeLabelFormatBasedOnBrowserLocale(language).day,
          week: DateTimeLabelFormatBasedOnBrowserLocale(language).week,
          month: DateTimeLabelFormatBasedOnBrowserLocale(language).month,
          year: DateTimeLabelFormatBasedOnBrowserLocale(language).year,
        },
        title: {
          text: 'Date2',
        },
        events: {
          afterSetExtremes(e) {
            e.preventDefault();
          },
        },
      },
    ],
    yAxis: {
      min: sensors.some(
        (sensor) => (sensor?.config?.type ?? Schema.SensorType.COUNTER) === Schema.SensorType.MEASUREMENT,
      )
        ? undefined
        : 0,
      max: sensors.every((sensor) =>
        (sensor?.time?.[0]?.samples ?? []).every((p) => p.data.accValue === 0 || p.data.accValue === null),
      )
        ? Math.max(maxValidatedSpeed, 10 / 1.1) * 1.1
        : undefined,
      maxPadding: 0.05,
      minRange: 0.01,
      tickInterval: isUptime ? 1 : undefined,
      endOnTick: false,
      title: {
        text: yAxisLabel,
      },
    },
    tooltip: {
      enabled: tooltipEnabled,
      shared: true,
      xDateFormat: xDateFormatBasedOnBrowserLocale(language),
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: tooltipEnabled,
            animation: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
      area: {
        marker: {
          radius: 2,
          enabled: tooltipEnabled,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
        events: {},
      },
    },
  };
  if (selectedTime) {
    const scatterSeries: SeriesScatterOptions = {
      type: 'scatter',
      showInLegend: false,
      enableMouseTracking: false,
      data: [
        {
          x: selectedTime.getTime(),
          y: 0,
          color: 'purple',
          marker: {
            radius: 6,
          },
        },
      ],
    };

    plotConfig.series!.push(scatterSeries);
  }

  const tempAlarmSeries: SeriesLineOptions[] = [];
  for (const alarmLimit of alarmLimits) {
    const alarmLimitId = new AlarmId(alarmLimit.id).alarmUUID;
    const matchingLine = tempAlarmSeries.filter((alarm) => new AlarmId(alarm.id!).alarmUUID === alarmLimitId)[0];
    const line: SeriesLineOptions = {
      visible: true,
      animation: false,
      type: 'line',
      name: alarmLimit.label.text,
      id: alarmLimit.id,
      data: [
        {
          x: alarmLimit.from,
          y: alarmLimit.value,
        },
        { x: alarmLimit.to, y: alarmLimit.value },
      ],
      color: alarmLimit.color,
      dashStyle: 'Dash',
      cursor: 'false',
      linkedTo: matchingLine ? matchingLine.id : undefined,
      states: {
        hover: {
          lineWidthPlus: 0,
        },
      },
      enableMouseTracking: false,
    };
    tempAlarmSeries.push(line);
    // @ts-ignore
    plotConfig.series!.push(line);
  }

  // Vertical annotations come in two variants: Point-in-time, or a time-range.
  // Point-in-time annotations are shown as labels.
  // Time-range annotations are shown as rectangular boxes.
  let verticalAnnotationsLabels: AnnotationsLabelOptions[] = [];
  if (legends.showAnnotations) {
    const verticalAnnotations = sensors.flatMap(
      ({ verticalAnnotations: verAnnotations }: Sensor) => verAnnotations ?? [],
    );

    verticalAnnotationsLabels = verticalAnnotations
      .filter((x) => x.timestampEnd === null)
      .map((verAnno: Schema.VerticalAnnotation) => {
        // We try to find the y-Value closest matching the sensor data
        // And if it fails, meaning there is no sensor data, we set the label at 10 as a dummy value
        let data = (plotConfig?.series![0] as any)?.data ?? [];
        let x = new Date(verAnno.timestamp).getTime();
        let y = 10;

        let estimated = closest(x, data);
        if (estimated?.y !== undefined && estimated?.y !== null) {
          y = estimated.y;
        }

        return {
          point: {
            x,
            y,
            xAxis: 0,
            yAxis: 0,
          },
          y: -25,
          id: verAnno.id,
          text: verAnno.label,
          allowOverlap: true,
          style: {
            width: '70%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
          } as any,
        };
      });

    let data: any[] = [];

    verticalAnnotations
      .filter((x) => x.timestampEnd !== null)
      .forEach((x) => {
        data.push({
          x: new Date(x.timestamp).getTime(),
          x2: new Date(x.timestampEnd!).getTime(),
          y: 1,
          custom: x,
          dataLabels: {
            format: '{point.name}',
            enabled: true,
            style: {
              width: '70%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              fontWeight: 'normal',
              fontSize: '11px',
            },
          },
          events: {
            click: (event: any) => {
              const custom: Schema.VerticalAnnotation = event.point.custom;
              if (onAnnotationClicked) {
                onAnnotationClicked(custom);
              }
            },
          },
          name: x.label,
          color: '#404040',
        });
      });

    let annotationRangesSeries: any = {
      showInLegend: false,
      data,
      animation: false,
      type: 'xrange',
      name: 'Annotations',
      cursor: 'pointer',
      color: '#404040',
      borderColor: '#404040',

      tooltip: {
        pointFormatter(this: any) {
          return `${this.name}`;
        },
        headerFormat: '',
      },
    };
    if (data.length) {
      plotConfig.series?.push(annotationRangesSeries);
    }
  }

  plotConfig.annotations = [
    {
      labels: verticalAnnotationsLabels,
      animation: false,
    },
  ] as Highcharts.AnnotationsOptions[];

  return plotConfig;
};

/**
 * Finds the neddle or the closest neighbor in the haystack.
 * Returns undefined, if haystack is empty
 * NOTE: Haystack must be sorted in ascending order.
 */
function closest<T extends { x: number; y: any }>(needle: number, haystack: T[]): T | undefined {
  const closestRightIndex = haystack.findIndex((point) => point.x >= needle);
  const closestLeftIndex = closestRightIndex - 1;
  const rightValue = haystack[closestRightIndex]?.x;
  const rightDistance = rightValue === undefined ? Number.MAX_VALUE : needle - rightValue;
  const leftValue = haystack[closestLeftIndex]?.x;
  const leftDistance = leftValue === undefined ? Number.MAX_VALUE : needle - leftValue;
  const shortestDistanceIdx = Math.abs(leftDistance) < Math.abs(rightDistance) ? closestLeftIndex : closestRightIndex;
  return haystack[shortestDistanceIdx];
}

export default getConfig;
